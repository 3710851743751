import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

const JobsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -110px;
  width: calc(100% + 110px);
`

const Job = styled.a`
  ${tw`shadow-md cursor-pointer my-6 m:my-12 font-body`}
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  flex: 1 0 300px;
  margin-left: 110px;

  @media (min-width: 640px) {
    max-width: calc(50% - 110px);
  }
  @media (min-width: 1200px) {
    max-width: calc(33.33333% - 110px);
  }

  .location {
    ${tw`px-4 text-white text-left`}
    background-image: linear-gradient(to bottom, #7c43c9, #481397);
    border-radius: 5px 5px 0 0;
    font-size: 13px;
    height: 26px;
    line-height: 0.73;
    padding-top: 10px;
    position: relative;
    z-index: 1;
    @media (min-width: 640px) {
      font-size: 15px;
      height: 28px;
    }
    &::before {
      transition: all 200ms;
      background-image: linear-gradient(to bottom, #05c56b, #00933e);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: -1;
    }
  }

  .position {
    ${tw`font-display font-bold text-left text-purple-dark`}
    font-size: 15px;
    letter-spacing: normal;
    line-height: 1;
    padding-top: 6px;
    transition: all 200ms;
    @media (min-width: 640px) {
      padding-top: 10px;
      font-size: 17px;
    }
  }

  .department {
    color: #292c30;
    font-size: 13px;
    @media (min-width: 640px) {
      font-size: 15px;
    }
  }

  .count {
    width: 53px;
  }

  .position-count {
    ${tw`font-secondary font-bold text-purple-dark`}
    font-size: 20px;
    height: 24px;
    transition: all 200ms;
    @media (min-width: 640px) {
      font-size: 22px;
      height: 30px;
    }
  }

  .positions-available {
    font-size: 11px;
    color: #292c30;
  }

  &:hover {
    .location::before {
      opacity: 1;
    }

    .position,
    .position-count {
      color: #01a04a;
    }
  }
`

const Jobs = ({ className, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        jobPostings(orderBy: order_ASC) {
          id
          positionTitle
          location
          linkUrl
          department
          positionsAvailable
        }
      }
    }
  `)
  const jobs = data.gcms.jobPostings

  return (
    <JobsWrapper className={`items-start py-4 m:py-16 ${className}`} {...rest}>
      {jobs.map((job) => (
        <Job
          key={job.id}
          id={job.id}
          href={job.linkUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="location">{job.location}</div>
          <div className="pl-4 p-2 flex flex-row justify-between">
            <div className="flex flex-col items-start justify-center flex-grow w-3/4">
              <h3 className="position">{job.positionTitle}</h3>
              <div className="department">{job.department}</div>
            </div>
            <div className="count flex flex-col items-center justify-center w-1/4">
              <div className="position-count">{job.positionsAvailable}</div>
              <div className="positions-available">
                Position{job.positionsAvailable === 1 ? "" : "s"}
              </div>
            </div>
          </div>
        </Job>
      ))}
    </JobsWrapper>
  )
}

export default Jobs

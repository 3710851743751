import React from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"
import FeaturedVideo from "components/sections/featured-video"
import SlantedSection from "components/sections/slant2"
import Offices from "components/offices"
import Jobs from "components/sections/jobs"
import BasicBackgroundSection from "components/basic-background-section"
import BgCareers from "images/bg/careers.jpg"

import { getSharePreviewUrls } from "utils/share-preview"

const HeaderContainer = styled.div`
  ${tw`h-screen flex flex-col items-center justify-between pt-20 mx-auto max-w-s m:max-w-m l:max-w-l`}
  padding-bottom: 4rem;

  @media (min-width: 640px) {
    height: 110vh;
    min-height: 1800px;
    padding-top: 16rem;

    .office {
      transform: scale(0.8);
      margin-bottom: -1.5rem;
    }
  }
  @media (min-width: 1200px) {
    min-height: 1400px;
    max-width: 1200px;
    .office {
      transform: scale(1);
      margin-bottom: unset;
    }
  }
  @media (min-width: 2200px) {
    min-height: 1600px;
  }
  @media (max-height: 900px) {
    padding-bottom: 2rem;
    padding-top: 6rem;
  }
`

const Header = styled.div`
  ${tw`font-body text-white text-center`}

  font-size: 13px;
  line-height: 1.92;
  @media (min-width: 640px) {
    font-size: 17px;
    line-height: 1.6;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 1.35;
  }

  p {
    ${tw`l:w-3/4 mx-auto`}
  }

  h1 {
    ${tw`font-display font-bold text-yellow`}
    font-size: 30px;
    line-height: 1.17;
    margin-bottom: 25px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 1.29;
    }
    @media (min-width: 1200px) {
      font-size: 60px;
      line-height: 1.42;
    }
  }

  h2 {
    ${tw`font-body`}
    font-size: 28px;
    @media (min-width: 640px) {
      font-size: 45px;
    }
    @media (min-width: 1200px) {
      font-size: 60px;
    }
  }
`

const Body = styled.div`
  ${tw`mx-auto max-w-s m:max-w-m l:max-w-l text-center text-grey-15`}

  font-size: 13px;
  line-height: 1.92;
  @media (min-width: 640px) {
    font-size: 15px;
    line-height: 1.8;
  }

  h2 {
    ${tw`font-display font-bold`}

    font-size: 30px;
    line-height: 1.17;
    margin-bottom: 13px;
    @media (min-width: 640px) {
      font-size: 50px;
      line-height: 0.96;
      margin-bottom: 25px;
    }
  }

  .featured-image {
    ${tw`border-solid border-4 border-grey-95 shadow-lg`}
    position: relative;
    top: -30px;
    @media (min-width: 640px) {
      top: -80px;
    }
  }

  .main {
    ${tw`mx-auto l:w-3/4 px-2 m:px-0`}
  }
`

const OfficesHeader = styled.div`
  ${tw`block m:hidden font-body text-white text-center mx-auto max-w-s`}

  font-size: 13px;
  line-height: 1.92;

  h2 {
    ${tw`font-display font-bold`}
    font-size: 30px;
    line-height: 1.17;
  }
`

export default ({ data }) => {
  const { page } = data.gcms

  const officesHeaderMobile = page?.contentBlocks.find(
    (b) => b.identifier === "offices-header-mobile"
  )

  if (!page) {
    return null
  }

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <BasicBackgroundSection
        Tag="section"
        image={BgCareers}
        color={`#000000`}
        repeat="no-repeat"
        size="cover"
        bgPosition="center"
      >
        <HeaderContainer>
          <Header
            dangerouslySetInnerHTML={{ __html: page?.headerContent?.html }}
          />
          <Offices className="hidden m:flex" />
        </HeaderContainer>
      </BasicBackgroundSection>
      <SlantedSection className="bg-grey-95 pb-8 pt-2 m:pb-32">
        <Body>
          {page.featuredImage && (
            <img
              src={page.featuredImage.url}
              alt={page.featuredImage.altText}
              className="featured-image"
            />
          )}
          <div
            className="main"
            dangerouslySetInnerHTML={{ __html: page?.body?.html }}
          />
          <Jobs />
        </Body>
      </SlantedSection>
      <SlantedSection className="bg-black m:pt-24 pb-16 m:pb-32">
        {page?.featuredVideo && <FeaturedVideo video={page.featuredVideo} />}
        {!!officesHeaderMobile && (
          <OfficesHeader
            dangerouslySetInnerHTML={{
              __html: officesHeaderMobile?.headerContent?.html,
            }}
          />
        )}
        <Offices className="flex m:hidden" />
      </SlantedSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/careers" }) {
        ...PageData
      }
    }
  }
`
